.searchContainer {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.seContainer {
    background-color: #1D1A48;
    width: 50%;
    height: 50%;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.searchBar {
    border: none;
    font-size: 17px;
    padding-left: 10px;
    background: transparent;
    color: #fff;
    width: 85%;
}
::placeholder {
    color: #d1d1d1;
}
.searchBar:focus {
    outline: none;
}

.icn {
    margin-right: 20px;
    font-size: 20px;
}

@media (max-width: 720px) {
    .seContainer {
        width: 90%;
    }
    .searchBar {
        font-size: 16px;
    }
}