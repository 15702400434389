.dcardContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 130px;
    min-width: 100px;
    margin-right: 10px;
    margin-left: 5px;
}

.dcardImg {
    margin-top: 5px;
    height: 65%;
    border-radius: 15px;
    box-shadow: 1px 1px lightblue, -1px -1px lightcoral;
    pointer-events: none;
}

.dcardName {
    font-size: 12px;
    height: 13%;
    overflow: hidden;
    text-overflow: clip;
    margin-top: 10px;
}
.dcardArtist {
    font-size: 10px;
    height: 17%;
    overflow: hidden;
    color: #d1d1d1;
}