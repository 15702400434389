.footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: #110F2F;
    position: fixed;
    bottom: 0;
    user-select: none;
}
.footerCt {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.footericnContainer {
    cursor: pointer;
    width: 80px;
    text-align: center;
}
.footericn {
    font-size: 20px;
}
.blue {
    color: #00FFAE;
}

@media(max-width: 720px) {
    .footerCt {
        width: 90%;
    }
}