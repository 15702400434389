.playerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.player {
    display: flex;
    flex-direction: row;
    width: 50%;
    margin-top: 20px;
}
.playHeaderButton {
    width: 10%;
    font-size: 20px;
}
.playHeader {
    width: 90%;
    margin-right: 10%;
    text-align: center;
    font-size: 20px;
}

.playCardContainer {
    background-color: #1D1A48;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    margin-top: 20px;
}

.songInfo {
    margin-top: 20px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.songName {
    font-size: 23px;
}
.songArtic {
    color: #d1d1d1;
}

.progressBar {
    width: 70%;
    height: 3px;
    background-color: #d1d1d1;
    display: flex;
}

.currentProgressBar {
    height: 100%;
    width: 50%;
    background: linear-gradient(90deg, #eb3495,#26ffed)
}

.currentProgressCircle {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: #26ffed;
    position: relative;
    bottom: 3px;
}

.timeTag {
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 20px;
}

.buttonContainer {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}
.songImage {
    height: 200px;
    width: 200px;
    border-radius: 200px;
    margin-bottom: 60px;
    border: 3px solid #fff;
    animation: 60s imgrotate;
}
@keyframes imgrotate {
    0% {transform: rotate(0deg)}
    100% {transform: rotate(360deg);}
}
.pBackIcn {
    cursor: pointer;
}
.alertContainer {
    background-color: rgba(0, 0, 0, 0.9);
    width: 500px;
    position: fixed;
    top: 200px;
    border: 1px solid red;
}
.alertContainer1 {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.alertDelete {
    color: red;
    font-size: 20px;
    margin: 5px;
    cursor: pointer;
}
.alertContainer2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.alertText {
    font-size: 20px;
    font-weight: 700;
    margin-left: 20px;
    margin-right: 10px;
}
.alertText2 {
    font-size: 15px;
    color: #d1d1d1;
    margin-top: 5px;
}
.alertImage {
    width: 100px;
    border-radius: 50px;
    margin-right: 20px;

}
@media(max-width: 720px) {
    .player {
        width: 90%;
    }

    .playCardContainer {
        width: 80%;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        height: 70%;
    }
    .progressBar {
        width: 90%;
    }
    .timeTag {
        width: 90%;
        font-size: 12px;
    }
    .buttonContainer {
        width: 70%;
    }
    .alertContainer {
        width: 450px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        height: 150px;
    }
    .alertText {
        font-size: 15px;
        font-weight: 600;
    }
    .alertText2 {
        font-size: 13px;
    }
    .alertImage {
        width: 50px;
    }
}
@media (max-width: 520px) {
    .alertContainer {
        width: 300px;
    }
    .alertText {
        font-size: 13px;
        font-weight: 600;
    }
    .alertText2 {
        font-size: 10px;
    }
    .alertImage {
        width: 50px;
    }
    .alertDelete {
        font-size: 15px;
    }
    .songName {
        font-size: 20px;
    }
    .songArtic {
        font-size: 17px;
    }
}
@media (max-width: 350px) {
    .alertContainer {
        width: 200px;
    }
}