.abContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.abHeader {
    width: 50%;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-top: 80px;
}
.abH {
    width: 50%;
    font-size: 18px;
    margin-top: 30px;
    font-weight: 700;
}
.abHi {
    font-size: 25px;
}
.abHistoryP {
    width: 50%;
    margin-top: 40px;
    font-size: 20px;
}
.abHistoryC {
    width: 50%;
}
.abThank {
    margin-top: 20px;
    font-size: 20px;
    margin-bottom: 60px;
}

@media (max-width: 720px) {
    .abHeader {
        width: 80%;
        font-size: 25px;
        margin-top: 100px;
    }
    .abH {
        width: 80%;
        font-size: 16px;
    }
    .abHi {
        font-size: 20px;
    }
    .abHistoryP {
        width: 80%;
        margin-top: 20px;
        font-size: 17px;
    }
    .abHistoryC {
        width: 80%;
    }
    .abThank {
        margin-top: 20px;
        font-size: 20px;
        margin-bottom: 60px;
    }
    
}
@media (max-width: 520px) {
    .abHeader {
        font-size: 20px;
        margin-top: 100px;
    }
    .abH {
        font-size: 16px;
    }
    .abHi {
        font-size: 18px;
    }
    .abHistoryP {
        width: 80%;
        margin-top: 20px;
        font-size: 15px;
    }
    .abHistoryC {
        width: 80%;
        font-size: 15px;
    }
    
}
